import React from 'react';
// import { FiDatabase } from 'react-icons/fi';

function ExtendedServices({ className }) {
    return (
        <>
            <section className={`inno-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="inno-section-title">
                                <h3 className="inno-title">
                                    Transforming businesses through our variety of services.
                                </h3>
                                <p>
                                    We build digital platforms and tools that allows any business to
                                    easily transition into the digital economy.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service-2 mt-40 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-archive fa-2x" />
                                </div>
                                <h4 className="title">Asset Management</h4>
                                <p>
                                    Enhance productivity, scale and streamline your business
                                    processes with our top-notch alternative asset management
                                    expertise
                                </p>
                                {/* <a href="#"> */}
                                {/*    Read Mor <i className="fal fa-arrow-right" /> */}
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service-2 item-2 mt-40 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-microchip fa-2x" />
                                </div>
                                <h4 className="title">Hi-Tech</h4>
                                <p>
                                    Digital transformation and managed services to fuel the
                                    perpetual process of change and to help you beat your
                                    competition comprehensively
                                </p>
                                {/* <a href="#"> */}
                                {/*    Read Mor <i className="fal fa-arrow-right" /> */}
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service-2 item-3 mt-40 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-fax fa-2x" />
                                </div>
                                <h4 className="title">Retail</h4>
                                <p>
                                    Deliver omni-channel customer experiences, creating product
                                    pages and websites that drive conversions, and securing your
                                    revenue from fraudsters
                                </p>
                                {/* <a href="#"> */}
                                {/*    Read Mor <i className="fal fa-arrow-right" /> */}
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service-2 item-4 mt-40 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-heartbeat fa-2x" />
                                </div>
                                <h4 className="title">HEALTHCARE</h4>
                                <p>
                                    IT services that give you the tools you need to simultaneously
                                    improve both quality and scope of care while also keeping the
                                    cost of transformation down
                                </p>
                                {/* <a href="#"> */}
                                {/*    Read Mor <i className="fal fa-arrow-right" /> */}
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service-2 item-5 mt-40 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-industry fa-2x" />
                                </div>
                                <h4 className="title">Manufacturing</h4>
                                <p>
                                    Improving your manufacturing planning, building of products
                                    (make or buy), selling of the products, manage operations and
                                    finance
                                </p>
                                {/* <a href="#"> */}
                                {/*    Read Mor <i className="fal fa-arrow-right" /> */}
                                {/* </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service-2 item-6 mt-40 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-book fa-2x" />
                                </div>
                                <h4 className="title">Education</h4>
                                <p>
                                    Develop and deliver high-quality software solutions that help
                                    educators like you operate more efficiently, cost-effectively,
                                    and securely
                                </p>
                                {/* <a href="#"> */}
                                {/*    Read Mor <i className="fal fa-arrow-right" /> */}
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ExtendedServices;
