import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/innogate-black-logo.png';
import logo from '../../assets/images/gate2innovate-logo.png';

function Drawer({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('home');
    const handler = (e, value) => {
        e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="gate2innovate-logo" height={60} />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, 'home')}
                                            id="home"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'services')}
                                            id="services"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                            <a href="#">Services</a>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height: item === 'services' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link to="/service-one">
                                                        Custom Development / Enhancement
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/service-two">
                                                        Product Engineering
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/service-three">
                                                        Application Re-engineering
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/service-four">
                                                        Application Maintenance
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'vision-and-mission')}
                                            id="vision-and-mission"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/vision-and-mission">Vision & Mission</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'career')}
                                            id="career"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/career">Career</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'contact')}
                                            id="contact"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/contact">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a
                                                className="lin"
                                                href="https://www.linkedin.com/company/gate2innovate/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                        {/*
                                        <li>
                                            <a href="$">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="$">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="$">
                                                <i className="fab fa-dribbble"></i>
                                            </a>
                                        </li>
                                        */}
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-envelope"></i>{' '}
                                                contact@gate2innovate.com
                                            </a>
                                        </li>
                                        {/*
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-phone"></i> +(642) 342 762 44
                                            </a>
                                        </li>
                                        */}
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt"></i> chennai
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
