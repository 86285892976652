import React, { useEffect } from 'react';
import useToggle from '../../../Hooks/useToggle';
import StickyMenu from '../../../lib/StickyMenu';
import BackToTop from '../../BackToTop';
import Footer from '../../Home/Footer';
import Project from '../../Home/Project';
import Drawer from '../../Mobile/Drawer';
import DetailsService from './DetailsService';
import HeaderService from '../HeaderService';
import HeroService from './HeroService';

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderService action={drawerAction.toggle} />
            <HeroService />
            <DetailsService />
            <Project />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Service;
