import React from 'react';
// import thumb from '../../../assets/images/service-details-thumb.jpg';

function DetailsService() {
    return (
        <>
            <section className="inno-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        {/*
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Innogate
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>innogate.com
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                {/*
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                */}
                                <div className="content">
                                    {/*
                                    <h3 className="title">Application Re-engineering</h3>
                                    <p>
                                        Tomfoolery wind up blag me old mucker chap super ruddy
                                        chancer bobby, vagabond it's your round cuppa cheesed off
                                        blower nice one at public school, Why cockup knackered pear
                                        shaped amongst spiffing good time he nicked it.
                                    </p>
                                    <span>
                                        He lost his bottle mufty spend a penny cheeky at public
                                        school daft get stuffed mate don't get shirty.
                                    </span>
                                    */}
                                    <p>
                                        Source code of thousands of business applications that
                                        support the business functions of organizations are in an
                                        orphaned state. This problem is further compounded when the
                                        engineers leave the development team.
                                    </p>
                                    <p className="mt-3">
                                        Application Mining brings out the inner secrets of
                                        application program logic, algorithms, entity relationships,
                                        database structures, class structures/ hierarchies and
                                        process flow charts. It enables the IT asset managers to
                                        quickly leverage on the existing code to maintain/upgrade
                                        applications to keep pace with business dynamics. These
                                        applications embody the business knowledge, process and
                                        rules, more importantly they serve as the starting point to
                                        improve the business process.
                                    </p>
                                    <p className="mt-3">
                                        Application Mining services provided by Innogate are
                                        designed to help companies to reverse engineer, document,
                                        and manage applications in a cost effective manner. Our
                                        approach includes long-term life cycle support through
                                        Application Knowledge Management process. Development teams
                                        spend considerable time and effort to understand existing
                                        code. Innogate can help to analyze, traverse, edit and
                                        document the application code, helping IT teams in
                                        maximizing their ROI on code development and maintenance.
                                    </p>
                                    <p className="mt-3">
                                        Our Application Mining process covers wide variety of
                                        technical platforms including all major databases and
                                        development tools such as Visual Basic, Visual C++,
                                        PowerBuilder, Oracle Developer, PL*SQL, Pro*C, Web
                                        development tools, C, C++, Java, and Script languages.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
