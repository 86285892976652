import React from 'react';
// import thumb from '../../../assets/images/service-details-thumb.jpg';

function DetailsService() {
    return (
        <>
            <section className="inno-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        {/*
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Innogate
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>innogate.com
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                {/*
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                */}
                                <div className="content">
                                    {/*
                                    <h3 className="title">Product Engineering</h3>
                                    <p>
                                        Tomfoolery wind up blag me old mucker chap super ruddy
                                        chancer bobby, vagabond it's your round cuppa cheesed off
                                        blower nice one at public school, Why cockup knackered pear
                                        shaped amongst spiffing good time he nicked it.
                                    </p>
                                    <span>
                                        He lost his bottle mufty spend a penny cheeky at public
                                        school daft get stuffed mate don't get shirty.
                                    </span>
                                    */}
                                    <p>
                                        Our Product Engineering services address customer
                                        requirements across the spectrum of the software product
                                        development life cycle – Accelerated Product Development
                                        (APD) and Product Management (PM).
                                    </p>
                                    <p className="pt-3">
                                        The Accelerated Product Development (APD) service offering
                                        for new product development and the early stages of the
                                        product development life cycle is supported by stringent
                                        quality framework. It uses an iterative methodology that’s
                                        necessitated due to highly usable interfaces and constantly
                                        changing requirements. Zirlen teams work on multiple
                                        technologies and have extensive experience in product
                                        design/development and associated processes such as
                                        configuration management, release management, change
                                        management, etc.
                                    </p>
                                    <p className="pt-3">
                                        Innogate’s Product Management service offering provides
                                        extensive maintenance services for products. These include
                                        enhancements, incremental releases and second and third
                                        level technical support helpdesks for resolving issues
                                        encountered by users. A robust defect tracking and
                                        resolution process and a specialized testing team support
                                        these services. Product Management has offerings covering
                                        the growth and sunset cycle of products.
                                    </p>
                                    <p className="pt-3">
                                        Innogate provides Professional Services & Product Value-adds
                                        to Independent Software Vendors for implementation,
                                        integration and custom development based upon the ISV’s
                                        product and other value-adds like training courseware
                                        development, internationalization services where it assists
                                        software companies in making their product marketable for
                                        the international market. Innogate consultants work with the
                                        ISV team to customize and implement the products for various
                                        clients.
                                    </p>
                                    <p className="pt-3">
                                        Innogate’s Product Support service involves addressing user
                                        tickets through a technical support helpdesk and providing
                                        bug fixes to ensure constant availability and performance of
                                        the product for end users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
