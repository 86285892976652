import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
// import Blog from './Blog';
// import Faq from './Faq';
// import Features from './Features';
import Footer from './Footer';
import Hero from './Hero';
import Header from './Header';
// import Pricing from './Pricing';
import Project from './Project';
import Services from './Services';
// import Team from './Team';
// import Testimonial from './Testimonial';
// import Traffic from './Traffic';
import ExtendedServices from './ExtendedServices';

function HomeNine() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Hero />
            <Services />
            <ExtendedServices className="pt-90" />
            {/* <Features /> */}
            {/* <Traffic /> */}
            {/* <Testimonial /> */}
            {/* <Team /> */}
            {/* <Pricing /> */}
            {/* <Faq /> */}
            {/* <Blog /> */}
            <Project />
            <Footer />
            <BackToTop />
        </>
    );
}

export default HomeNine;
