import React from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/innogate-black-logo.png';
import logo from '../../assets/images/gate2innovate-logo.png';

function Footer({ className }) {
    return (
        <>
            <section className={`inno-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="gate2innovate-logo" height={60} />
                                    </a>
                                </div>
                                <p>
                                    From strategy consulting right through to implementing IT
                                    solutions for customers
                                </p>
                                <a href="#">
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                                <div className="social mt-30">
                                    <ul>
                                        {/* <li> */}
                                        {/*    <a href="#"> */}
                                        {/*        <i className="fab fa-facebook-f" /> */}
                                        {/*    </a> */}
                                        {/* </li> */}
                                        {/* <li> */}
                                        {/*    <a href="#"> */}
                                        {/*        <i className="fab fa-twitter" /> */}
                                        {/*    </a> */}
                                        {/* </li> */}
                                        {/* <li> */}
                                        {/*    <a href="#"> */}
                                        {/*        <i className="fab fa-pinterest-p" /> */}
                                        {/*    </a> */}
                                        {/* </li> */}
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/gate2innovate/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/vision-and-mission">Vision & Mission</Link>
                                    </li>
                                    <li>
                                        <Link to="/career">Career</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Services</h4>
                                <ul>
                                    <li>
                                        <Link to="/service-one">
                                            Custom Development / Enhancement
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/service-two">Product Engineering</Link>
                                    </li>
                                    <li>
                                        <Link to="/service-three">Application Re-engineering</Link>
                                    </li>
                                    <li>
                                        <Link to="/service-four">Application Maintenance</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i
                                                className="fal fa-envelope"
                                                style={{ marginTop: 3 }}
                                            />
                                            contact@gate2innovate.com
                                        </a>
                                    </li>
                                    {/* <li> */}
                                    {/*    <a href="#"> */}
                                    {/*        <i className="fal fa-phone" /> +(642) 342 762 44 */}
                                    {/*    </a> */}
                                    {/* </li> */}
                                    <li>
                                        <a href="#">
                                            <i
                                                className="fal fa-map-marker-alt"
                                                style={{ marginTop: 2 }}
                                            />
                                            Chennai
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://wa.me/message/DKM7RSKF53OVG1"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fas fa-comment" />
                                                Let's Talk
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2022 Gate2Innovate. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;
