import React from 'react';
// import thumb from '../../../assets/images/service-details-thumb.jpg';

function DetailsService() {
    return (
        <>
            <section className="inno-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        {/*
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Innogate
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>innogate.com
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                {/*
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                */}
                                <div className="content">
                                    {/*
                                    <h3 className="title">Application Maintenance</h3>
                                    <p>
                                        Tomfoolery wind up blag me old mucker chap super ruddy
                                        chancer bobby, vagabond it's your round cuppa cheesed off
                                        blower nice one at public school, Why cockup knackered pear
                                        shaped amongst spiffing good time he nicked it.
                                    </p>
                                    <span>
                                        He lost his bottle mufty spend a penny cheeky at public
                                        school daft get stuffed mate don't get shirty.
                                    </span>
                                    <p>
                                        Innogate’s application management services comprise
                                        post-implementation maintenance, support and enhancements
                                        through maintenance plans that identify and remove potential
                                        threats to overall system stability.
                                    </p>
                                    */}
                                    <p className="pt-3">
                                        While the management of your company can focus on your core
                                        business, our experienced practitioners will ensure quality
                                        operations to meet your specific needs. Our services help
                                        streamline application support processes and address all the
                                        key issues, improving operating performance, increasing
                                        efficiency and productivity, and controlling costs for all
                                        areas of the application lifecycle, from development or
                                        enhancements to everyday maintenance or support.
                                    </p>
                                    <p className="pt-3">
                                        Our application management services deliver the following
                                        benefits:
                                        <ul>
                                            <li>1. Optimum resources management</li>
                                            <li>2. Reduction in total cost of ownership</li>
                                            <li>3. Guaranteed service levels</li>
                                            <li>4. Faster time to market for new initiatives</li>
                                        </ul>
                                    </p>
                                    <p className="pt-3">
                                        Our application management process consists of three phases:
                                        <ul>
                                            <li>1. Preparation and planning</li>
                                            <li>2. Knowledge transfer</li>
                                            <li>3. Steady state</li>
                                        </ul>
                                    </p>
                                    <p className="pt-3">
                                        All the three phases emphasize on production support,
                                        performance management, change management and continuous
                                        enhancements. From the beginning of an engagement, we ensure
                                        that the mutually agreed performance levels in terms of
                                        price, quality, productivity and services are achieved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
