import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import hero from '../../assets/images/hero-thumb-9.png';
import shapeOne from '../../assets/images/shape/1.png';
import shapeTwo from '../../assets/images/shape/2.png';
import shapeThree from '../../assets/images/shape/3.png';
import shapeFour from '../../assets/images/shape/4.png';
import InnogateVideo from '../../assets/videos/gate2innovate-logo.mp4'

function Hero() {

	const [playVideo, setPlayVideo] = useState(false);
	const videoRef = useRef(null);

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		videoRef.current.play();
	// 	}, 3000);
	// 	return () => clearTimeout(timer);
	// }, []);

    return (
        <>
            <section className="inno-hero-area inno-hero-8-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="inno-hero-content inno-hero-content-8">
                                <h1 className="inno-title">We help you to grow your business</h1>
								{/*<ReactPlayer*/}
								{/*	// playing*/}
								{/*	url={InnogateVideo}*/}
								{/*	width="90%"*/}
								{/*	config={{*/}
								{/*		file: {*/}
								{/*			attributes: {*/}
								{/*				autoPlay: true,*/}
								{/*				muted: false*/}
								{/*			}*/}
								{/*		}*/}
								{/*	}}*/}
								{/*/>*/}
								<div style={{marginBottom: 12}}>
									<video width="90%" autoPlay={true} muted={true} controls>
										<source src={InnogateVideo} type="video/mp4" />
									</video>
								</div>
                                <p>
                                    At Gate2Innovate, our mission is to help people and businesses
                                    to succeed in the digital economy.
                                </p>
                                <ul>
                                    <li>
                                        <a
                                            href="https://wa.me/message/DKM7RSKF53OVG1"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span>Let's Talk!</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="inno-hero-thumb-6">
                                <div
                                    className="thumb text-center wow animated fadeInUp"
                                    data-wow-duration="1000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={hero} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-8-shape-1">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="home-8-shape-2">
                    <img src={shapeFour} alt="" />
                </div>
                <div className="home-8-shape-3">
                    <img src={shapeOne} alt="" />
                </div>
                <div className="home-8-shape-4">
                    <img src={shapeTwo} alt="" />
                </div>
            </section>
        </>
    );
}

export default Hero;
