import React from 'react';
import iconOne from '../../assets/images/icon/1.png';
import iconTwo from '../../assets/images/icon/2.png';
import iconThree from '../../assets/images/icon/3.png';

function CompanyValue() {
    return (
        <>
            <section className="inno-service-area inno-service-3-area pt-90 pb-100" id="service">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="inno-section-title text-center">
                                <h3 className="inno-title">We work by our values.</h3>
                                <p>
                                    Commitment to our company values has been core to
                                    Gate2Innovate's successes so far. They define the principles by
                                    which we work with one another, and our stakeholders, to drive
                                    the company forward. We're incredibly proud of the culture we
                                    have cultivated, and our values continue to hold us accountable.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service inno-single-services-3 text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={iconOne} alt="" />
                                </div>
                                <h4 className="inno-title">
                                    We prioritise health without exception 🍎
                                </h4>
                                <p>
                                    Good health is too often taken for granted so we make a
                                    conscious effort to nurture it in all forms. The physical,
                                    mental, and financial health of our team is prioritised so that
                                    we can succeed in our mission to accelerate the discovery of
                                    innovative medicines.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service inno-single-services-3 text-center mt-30 item-2 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={iconTwo} alt="" />
                                </div>
                                <h4 className="inno-title">
                                    Move as one team united by a shared mission 🤝
                                </h4>
                                <p>
                                    Although technology and biology have different heritages, we
                                    must champion both in equal measure to succeed. A combination of
                                    open communication, collaboration, and trust between cross
                                    functional teams creates unity whilst we work towards our
                                    mission.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="inno-single-service inno-single-services-3 text-center mt-30 item-3 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={iconThree} alt="" />
                                </div>
                                <h4 className="inno-title">
                                    Fiercely seek new opportunities to grow 🌻
                                </h4>
                                <p>
                                    Only by embracing failure and learning from our mistakes, are we
                                    able to pursue new horizons and face challenges head on. We
                                    question our assumptions through a pragmatic lens and encourage
                                    curiosity to promote innovation at every opportunity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CompanyValue;
