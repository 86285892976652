import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import Footer from '../Home/Footer';
import Project from '../Home/Project';
import Drawer from '../Mobile/Drawer';
import CompanyValue from './CompanyValue';
// import DetailsService from './DetailsService';
import HeaderService from './HeaderService';
import HeroService from './HeroService';
import Jobs from './jobs';
import WorkPart from './WorkPart';

function Career() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderService action={drawerAction.toggle} />
            <HeroService />
            <CompanyValue />
            <WorkPart />
            {/* <DetailsService /> */}
            <Jobs />
            <Project />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Career;
