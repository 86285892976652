import React from 'react';
// import thumb from '../../../assets/images/service-details-thumb.jpg';

function DetailsService() {
    return (
        <>
            <section className="inno-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        {/*
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                                <div className="service-category-widget">
                                    <ul>
                                        <li>
                                            <i className="fal fa-home"></i>Fast Development
                                        </li>
                                        <li>
                                            <i className="fal fa-tag"></i>App, UX
                                        </li>
                                        <li>
                                            <i className="fal fa-user-friends"></i>20-30
                                        </li>
                                        <li>
                                            <i className="fal fa-bookmark"></i>Innogate
                                        </li>
                                        <li>
                                            <i className="fal fa-globe"></i>innogate.com
                                        </li>
                                    </ul>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-download"></i>
                                        <span>Download docs</span>
                                    </a>
                                </div>
                                <div className="service-download-widget">
                                    <a href="#">
                                        <i className="fal fa-file-pdf"></i>
                                        <span>Characteristics</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                {/*
                                <div className="thumb">
                                    <img src={thumb} alt="" />
                                </div>
                                */}
                                <div className="content">
                                    {/*
                                    <h3 className="title">Custom Development / Enhancement</h3>
                                    <p>
                                        Tomfoolery wind up blag me old mucker chap super ruddy
                                        chancer bobby, vagabond it's your round cuppa cheesed off
                                        blower nice one at public school, Why cockup knackered pear
                                        shaped amongst spiffing good time he nicked it.
                                    </p>
                                    <span>
                                        He lost his bottle mufty spend a penny cheeky at public
                                        school daft get stuffed mate don't get shirty.
                                    </span>
                                    */}
                                    <p>
                                        Over the years we have managed to build a solid team of
                                        software outsourcing professionals that come from various
                                        backgrounds and expand the creative potential of the
                                        company. The expertise that they posses embraces a wide
                                        range of custom programming skills involving the latest and
                                        most effective development technologies, such as Spring Boot
                                        (Java, Groovy), Python and many more. This to a great extent
                                        defines the quality and reliability of the custom software
                                        applications that we develop.
                                    </p>
                                    <p className="pt-3">
                                        Innogate Solutions Pvt Ltd. is a rapidly growing custom
                                        development and software outsourcing company with
                                        headquarters in Dallas, TX. Our offshore development
                                        department is located in Chennai, India. We specialize in
                                        the development of custom software applications and offshore
                                        software outsourcing services. Specifically, our company
                                        carries out custom programming, database design, and
                                        client-server and internet/intranet software application
                                        development.
                                    </p>
                                    <p className="pt-3">
                                        We deliver custom application programming services to end
                                        customers and we provide offshore software outsourcing
                                        services for other IT companies. Our competence and
                                        experience ensure that we deliver excellent services and
                                        products to our customers. At every stage of the development
                                        process, from conceptual design to product release, the
                                        highest quality standards are maintained. Our extensive
                                        communication facilities allow us to keep in touch with our
                                        customers 24 hours a day.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsService;
