import React from 'react';
import ReactNative from '../../assets/images/career/react-native.png';
import ReactRedux from '../../assets/images/career/react-redux.png';
import JavaSpring from '../../assets/images/career/java-spring.png';

function Jobs() {
    return (
        <>
            <section className="inno-blog-area pt-90 pb-95">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inno-section-title text-center">
                                <h3 className="inno-title">Join us at Gate 2 Innovate</h3>
                                <p>We are hiring for below roles</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inno-blog-item-3 inno-blog-item-8 mt-30">
                                <div className="thumb">
                                    <img src={ReactNative} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="title">
                                        <a href="#" style={{ pointerEvents: 'none' }}>
                                            React Native Mobile Developer
                                        </a>
                                    </h5>
                                    <div className="meta-item">
                                        <ul>
                                            <li>
                                                We are looking for a React Native developer
                                                interested in building performant mobile apps on
                                                both the iOS and Android platforms. You will be
                                                responsible for architecting and building these
                                                applications, as well as coordinating with the teams
                                                responsible for other layers of the product
                                                infrastructure. Building a product is a highly
                                                collaborative effort, and as such, a strong team
                                                player with a commitment to perfection is required.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="inno-blog-item-3 inno-blog-item-8 mt-30">
                                <div className="thumb">
                                    <img src={ReactRedux} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="title">
                                        <a href="#" style={{ pointerEvents: 'none' }}>
                                            React Redux Frontend Developer
                                        </a>
                                    </h5>
                                    <div className="meta-item">
                                        <ul>
                                            <li>
                                                We are looking for a skilled react.js developer to
                                                join our front-end development team. In this role,
                                                you will be responsible for developing and
                                                implementing user interface components using
                                                React.js concepts and workflows such as Redux and
                                                Webpack. You will also be responsible for profiling
                                                and improving front-end performance and documenting
                                                our front-end codebase.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="inno-blog-item-3 inno-blog-item-8 mt-30">
                                <div className="thumb">
                                    <img src={JavaSpring} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="title">
                                        <a href="#" style={{ pointerEvents: 'none' }}>
                                            Java Backend Developer
                                        </a>
                                    </h5>
                                    <div className="meta-item">
                                        <ul>
                                            <li>
                                                We are looking for a Java developer responsible for
                                                building Spring Boot based Java applications. This
                                                includes anything between complex groups of back-end
                                                services and their client-end (desktop and mobile)
                                                counterparts. Your primary responsibility will be to
                                                design and develop these applications, and to
                                                coordinate with the rest of the team working on
                                                different layers of the infrastructure. Thus, a
                                                commitment to collaborative problem solving,
                                                sophisticated design, and product quality is
                                                essential.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Jobs;
