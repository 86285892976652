import React from 'react';
import { FcIdea, FcCustomerSupport, FcSettings, FcGlobe } from 'react-icons/fc';
// import IconOne from '../../assets/images/icon/1.png';
// import IconTwo from '../../assets/images/icon/2.png';
// import IconThree from '../../assets/images/icon/3.png';
// import IconFour from '../../assets/images/icon/4.png';
// import IconOne from '../../assets/images/icon/5.svg';

function Services({ className }) {
    return (
        <section className={`inno-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="inno-section-title text-center">
                            <h3 className="inno-title">How we will work with you</h3>
                            <p>Gate2Innovate's Project Process</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="inno-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                {/* <img src={FcIdea} alt="" /> */}
                                <FcIdea size={50} />
                                <span>1</span>
                            </div>
                            <h4 className="inno-title">Idea</h4>
                            <p>
                                It all starts with an idea of yours, we get that clearly and start
                                drafting the plan
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="inno-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                {/* <img src={IconTwo} alt="" /> */}
                                <FcSettings size={50} />
                                <span>2</span>
                            </div>
                            <h4 className="inno-title">Develop</h4>
                            <p>
                                Our team of experts get into the picture and start working on the
                                development part
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="inno-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                {/* <img src={IconThree} alt="" /> */}
                                <FcGlobe size={50} />
                                <span>3</span>
                            </div>
                            <h4 className="inno-title">Launch</h4>
                            <p>
                                We build & deliver the product which will be subjected to multiple
                                testing
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="inno-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                {/* <img src={IconFour} alt="" /> */}
                                <FcCustomerSupport size={50} />
                                <span>4</span>
                            </div>
                            <h4 className="inno-title">Support</h4>
                            <p>
                                Unlike our rivals we offer post support once the project is
                                delivered
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
