import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <a href="#">
                        Services <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu" style={{ minWidth: 360, maxWidth: 360 }}>
                        <li>
                            <Link to="/service-one">Custom Development / Enhancement</Link>
                        </li>
                        <li>
                            <Link to="/service-two">Product Engineering</Link>
                        </li>
                        <li>
                            <Link to="/service-three">Application Re-engineering</Link>
                        </li>
                        <li>
                            <Link to="/service-four">Application Maintenance</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/vision-and-mission">Vision & Mission</Link>
                </li>
                <li>
                    <Link to="/career">Career</Link>
                </li>
                <li>
                    <Link to="/about-us">About Us</Link>
                </li>
                <li>
                    <Link to="/contact">Contact Us</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
