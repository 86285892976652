import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../Home/Footer';
import Drawer from '../Mobile/Drawer';
import Header from './Header';
import Hero from './Hero';
import Forms from './Forms';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header drawer={drawer} action={drawerAction.toggle} />
            <Hero
                title="Contact Us"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/contact', title: 'contact us' },
                ]}
            />
            <Forms />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Contact;
