import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Home from './components/Home';
import ServiceOne from './components/Services/ServiceOne';
import ServiceTwo from './components/Services/ServiceTwo';
import ServiceThree from './components/Services/ServiceThree';
import ServiceFour from './components/Services/ServiceFour';
import VisionAndMission from './components/VisionAndMission';
import Career from './components/Career';
import Contact from './components/Contact';
import AboutUs from './components/AboutUs';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`inno-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`inno-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/service-one" component={ServiceOne} />
                            <Route exact path="/service-two" component={ServiceTwo} />
                            <Route exact path="/service-three" component={ServiceThree} />
                            <Route exact path="/service-four" component={ServiceFour} />
                            <Route exact path="/vision-and-mission" component={VisionAndMission} />
                            <Route exact path="/career" component={Career} />
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/error" component={Error} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
