import React from 'react';

function Blog() {
    return (
        <>
            <div className="single-post-area">
                {/*
                <p>
                    That faff about the full monty blower bubble and squeak cheeky old matie boy
                    burke, the bee's knees what a load of rubbish golly gosh mufty is Elizabeth
                    squiffy, lurgy chimney pot Jeffrey Richard naff Queen's English cheesed off.
                    That faff about the full monty blower bubble and squeak cheeky old matie boy
                    burke, the bee's knees what a load of rubbish golly gosh mufty is Elizabeth
                    squiffy, lurgy chimney pot Jeffrey Richard naff Queen's English cheesed off.
                </p>
                */}
                <h4 className="article-title">Vision Statement</h4>
                <blockquote>
                    <p>
                        Be recognized as the world’s leading provider of business solutions with
                        cutting edge technologies and top-notch technical expertise.
                    </p>
                    <cite>Our Vision</cite>
                </blockquote>
                <h4 className="article-title">Mission Statement</h4>
                <blockquote>
                    <p>
                        To create and provide quality business solutions by applying human ingenuity
                        and technology. We aspire to make life better for our customers to meet the
                        needs of their customers. Whether it is sharing knowledge, making
                        transactions, or providing entertainment, we help businesses to anticipate
                        the needs of their customers and interact more efficiently and productively
                        with them. We aspire to be the company that our clients would gladly
                        recommend to their colleagues and friends because of our attention to their
                        goals and needs.
                    </p>
                    <cite>Our Mission</cite>
                </blockquote>
            </div>
        </>
    );
}

export default Blog;
